import * as React from "react"
import { Padding } from "./Styles"

const PortfolioHeader = ({ title, description }) => {
    return (
        <div>
            <h1>{title}</h1>
            <h2>{description}</h2>
        </div >
    )
}

export default PortfolioHeader